import { DatePicker, Select, Table } from 'antd';
import moment, { Moment } from 'moment';
import * as React from 'react';
import EventTypeModel from '../../../models/EventTypeModel';
import SignalrHelper from '../../../shared/helpers/signalrHelper';
import ProspectStatViewModel, { ProspectViewModel } from '../../../models/ProspectStatViewModel/ProspectStatViewModel';

import './ProspectsTab.css';

export interface Props {
    children?: React.ReactNode
}

export interface State {
    startDate: Date;
    endDate: Date;
    period: Period;
    data: ProspectStatViewModel[];
    isLoading: boolean;
    eventTypes: EventTypeModel[];
    selectedQueryType: QueryType;
    verified: Verified;
}

export enum Period {
    PerYear,
    PerMonth,
    PerDay
}

export enum QueryType {
    All,
    Plus1,
    Zero
}

export enum Verified {
    All,
    Verified,
    NonVerified
}

const { Option } = Select;

export default class ProspectsTab extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            startDate: new Date('2004-01-01'),
            endDate: new Date(),
            period: Period.PerMonth,
            data: [],
            isLoading: true,
            eventTypes: [],
            selectedQueryType: QueryType.Plus1,
            verified: Verified.All
        }
    }

    componentDidMount() {
        this.GetProspectStats();
    }


    GetProspectStats = () => {
        this.setState({ isLoading: true }, () => {
            this.signalr.invoke("GetProspectStats",
                this.state.startDate,
                this.state.endDate,
                this.state.period,
                this.state.verified
            ).then(
                (data: ProspectStatViewModel[]) => {
                    this.setState({ data: data, isLoading: false });
                }
            );
        });

    }

    onChangeStart = (value: Moment | null, dateString: string) => {
        this.setState({ startDate: value.toDate() }, () => {
            this.GetProspectStats();
        });
    }

    onChangeEnd = (value: Moment | null, dateString: string) => {
        this.setState({ endDate: value.toDate() }, () => {
            this.GetProspectStats();
        });
    }

    onChangeType = (value: number) => {
        this.setState({ period: value }, () => {
            this.GetProspectStats();
        });
    }

    onChangeVerified = (value: number) => {
        this.setState({ verified: value }, () => {
            this.GetProspectStats();
        });
    }

    getExpandedRow = (prospect: ProspectStatViewModel) => {
        return <div className="row expanded-row">
            <div className="col-sm-12">
                <Table
                    bordered={true}
                    columns={[
                        {
                            title: 'Customer', dataIndex: 'customerName', key: 'customerName', width: 300,
                            render: (record: ProspectViewModel) => <>{<a href={`../adm/editCompany.aspx?id=${record.customerId}`}>{record.customerName}</a>}</>,
                        },
                        {
                            title: 'Country', dataIndex: 'country', key: 'country', width: 200
                        },
                        {
                            title: 'Register date', width: 200,
                            render: (record: ProspectViewModel) => <>{moment(new Date(record.registerDate)).format('YYYY-MM-DD')}</>
                        }
                    ]}
                    pagination={{
                        pageSize: 10,
                    }}
                    rowKey="customerId"
                    dataSource={prospect.customers}
                />
            </div>
        </div>;
    }

    getColumnStyle = (value: number) => {
        return value >= 0 ? 'positive-value' : 'negative-value';
    };

    render() {
        return (
            <div className="row " style={{ maxWidth: 1300 }}>
                <h2 className="col-sm-12">Prospects</h2>
                <div className="col-sm-12">
                    <DatePicker
                        allowClear={false}
                        value={moment(this.state.startDate)}
                        onChange={this.onChangeStart}
                    />
                    <DatePicker
                        allowClear={false}
                        value={moment(this.state.endDate)}
                        onChange={this.onChangeEnd}
                    />
                    <Select
                        onChange={this.onChangeType}
                        value={this.state.period}
                        style={{ width: "250px" }}
                    >
                        <Option value={Period.PerYear}>Per year</Option>
                        <Option value={Period.PerMonth}>Per month</Option>
                        <Option value={Period.PerDay}>Per day</Option>
                    </Select>
                    <Select onChange={this.onChangeVerified}
                        value={this.state.verified}
                        style={{ width: "150px" }}>
                        <Option value={Verified.All}>All</Option>
                        <Option value={Verified.Verified}>Verified</Option>
                        <Option value={Verified.NonVerified}>Non-verified</Option>
                    </Select>
                    <span style={{ marginLeft: 16 }}>Total: {this.state.data.map(item => item.count).reduce((accumulator, currentValue) => accumulator + currentValue, 0)}</span>
                </div>
                <div className="col-sm-12">
                    <Table
                        bordered
                        loading={this.state.isLoading}
                        columns={[
                            { title: 'Date', dataIndex: 'date', key: 'date' },
                            { title: 'Count', dataIndex: 'count', key: 'count', align: 'right' },
                            {
                                title: 'Diff',
                                dataIndex: 'diff',
                                key: 'diff',
                                align: 'right',
                                render: (value: number, record: ProspectStatViewModel) => (
                                    <div className={this.getColumnStyle(record.diff)}>{value}</div>
                                ),
                            },
                            {
                                title: '% diff',
                                dataIndex: 'percentDiff',
                                key: 'percentDiff',
                                align: 'right',
                                render: (value: number, record: ProspectStatViewModel) => (
                                    <div className={this.getColumnStyle(record.percentDiff)}>
                                        {value + '%'}
                                    </div>
                                ),
                            },
                            {
                                title: 'Period diff',
                                dataIndex: 'periodDiff',
                                key: 'periodDiff',
                                align: 'right',
                                width: this.state.period === Period.PerMonth ? 100 : 0,
                                render: (value: number, record: ProspectStatViewModel) => (
                                    <div className={this.getColumnStyle(record.periodDiff)}>{value}</div>
                                ),
                            },
                            {
                                title: 'Period % diff',
                                dataIndex: 'periodPercentDiff',
                                key: 'periodPercentDiff',
                                align: 'right',
                                width: this.state.period === Period.PerMonth ? 100 : 0,
                                render: (value: number, record: ProspectStatViewModel) => (
                                    <div className={this.getColumnStyle(record.periodPercentDiff)}>
                                        {value + '%'}
                                    </div>
                                ),
                            },
                            ]
                        }
                        rowKey="date"
                        dataSource={this.state.data}
                    />
                </div>
            </div>
        )
    }
}