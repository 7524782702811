import * as React from 'react';
import { Route } from 'react-router';
import './custom.css';
import EditCampaign from './components/integrated/EditCampaign';
import CampaignAdmin from './components/integrated/CampaignAdmin';
import Prospects from './components/shared/Prospects';
import LicenseExpiration from './components/integrated/LicenseExpiration';
import IPLimitation from './components/integrated/IPLimitation';
import DemosStats from './components/integrated/DemosStats';
import CreatePromoCode from './components/integrated/CreatePromoCode';
import ContactCollection from './components/integrated/ContactCollection';
import ResetPassword from './components/integrated/ResetPassword';
import ScreenshotEditor from './components/integrated/ScreenshotEditor';
import Screenshots from './components/integrated/Screenshots';
import CustomerManager from './components/integrated/CustomerManager';
import LicenseDuplicateUsage from './components/integrated/LicenseDuplicateUsage';
import AcademyMainPage from './components/academy/AcademyMainPage';
import AcademyCourse from './components/academy/AcademyCourse';
import AcademyModule from './components/academy/AcademyModule';
import ActiveMaintenance from './components/integrated/ActiveMaintenance';
import ZendeskAdmin from './components/integrated/ZendeskAdmin';
import PerContinent from './components/integrated/PerContinent';
import PerCountry from './components/integrated/PerCountry';
import CustomerWithOnePlus from './components/integrated/CustomerWithOnePlus';
import NewLicensesStats from './components/integrated/NewLicensesStats';
import DisplayedLangs from './components/integrated/DisplayedLangs';
import IpLimitationByCustomer from './components/integrated/IpLimitationByCustomer';
import ExtendOfflineMaintenanceCheck from './components/integrated/ExtendOfflineMaintenanceCheck';
import GenerateSupportCodes from './components/shared/GenerateSupportCodes';
import BlockedDomains from './components/integrated/BlockedDomains';
import EmailAutomation from './components/integrated/EmailAutomation';
import CustomerEditor from './components/integrated/CustomerEditor';
import Guide from './components/integrated/Guide';
import SubscriptionVsMaintenance from './components/integrated/SubscriptionVsMaintenance';
import EmailAutomationHistory from './components/integrated/EmailAutomationHistory';
import TagManager from 'react-gtm-module';
import { initAnalytics, routeTracker as RouteTracker } from './shared/components/GoogleAnalytics/GoogleAnalytics';
import DownloadContainer from './components/main/DownloadContainer';
import AdminMenu from './components/integrated/AdminMenu';
import AdminPermissionManager from './components/integrated/AdminPermissionManager';
import CloudPermissions from './components/integrated/CloudPermissions';
import FileManager from './components/integrated/FileManager';
import WatchList from './components/shared/WatchList';
import NetsOrders from './components/integrated/NetsOrders/NetsOrders';


export interface Props {
    children?: React.ReactNode
}

export interface State {
    userLoaded: boolean;
    locationLoaded: boolean;
}

const tagManagerArgs = {
    gtmId: 'GTM-T9KDBM'
}

export default class App extends React.Component<Props, State>{

    constructor(props: Props) {
        super(props)

        this.state = {
            userLoaded: false,
            locationLoaded: false,
        }
    }

    componentDidMount() {
        this.getUserFromMain();
        TagManager.initialize(tagManagerArgs);
    }

    getUserFromMain = () => {
        let user = '';
        if (window.location.host.includes("localhost")) {
            user = '';
            (window as any)['user'] = user;
            this.setState({ userLoaded: true });
        } else {
            fetch(window.location.protocol + "//" + window.location.host + "/wsx/2005-09-26/VCWebService.asmx/GetUserFromMain")
                .then(res => res.text())
                .then(
                    (result) => {
                        if (result) {
                            user = result
                                .replace('<string xmlns="http://tempuri.org/">', '')
                                .replace('</string>', '')
                                .replace('<?xml version="1.0" encoding="utf-8"?>', '')
                                .trim();
                            (window as any)['user'] = user;
                        }
                        this.setState({ userLoaded: true });
                    },
                    (error) => {
                        this.setState({ userLoaded: true });
                        console.log(error);
                    }
                );
        }
    }

    initGoogleAnalytics = (): JSX.Element => {
        const debug = process.env.NODE_ENV === 'development';
        initAnalytics({ debug });
        return <RouteTracker />;
    }

    render() {
        return (
            <>
                <div className="reactcss ant-modal-root ">
                    {this.initGoogleAnalytics()}
                    <Route path='/licenseexpiration' component={LicenseExpiration} />
                    <Route path='/iplimitation' component={IPLimitation} />
                    <Route path='/demos' component={DemosStats} />
                    <Route path='/am' component={ActiveMaintenance} />
                    <Route path='/prospects' component={Prospects} />
                    <Route path='/promo' component={CreatePromoCode} />
                    <Route path='/campaignadmin' component={CampaignAdmin} />
                    <Route path='/editcampaign' component={EditCampaign} />
                    <Route path='/contactcollection' component={ContactCollection} />
                    <Route path='/resetpassword' component={ResetPassword} />
                    <Route path='/screenshoteditor' component={ScreenshotEditor} />
                    <Route path='/screenshots' component={Screenshots} />
                    <Route path='/customermanager' component={CustomerManager} />
                    <Route path='/licenseduplicate' component={LicenseDuplicateUsage} />
                    <Route path='/academy' component={AcademyMainPage} />
                    <Route path='/academycourse' component={AcademyCourse} />
                    <Route path='/academymodule' component={AcademyModule} />
                    <Route path='/zendeskadmin' component={ZendeskAdmin} />
                    <Route path='/percontinent' component={PerContinent} />
                    <Route path='/percountry' component={PerCountry} />
                    <Route path='/plusone' component={CustomerWithOnePlus} />
                    <Route path='/newlicenses' component={NewLicensesStats} />
                    <Route path='/displayedlangs' component={DisplayedLangs} />
                    <Route path='/iplimitationcustomer' component={IpLimitationByCustomer} />
                    <Route path='/extend' component={ExtendOfflineMaintenanceCheck} />
                    <Route path='/test' component={GenerateSupportCodes} />
                    <Route path='/blockeddomains' component={BlockedDomains} />
                    <Route path='/emailautomationitem' component={EmailAutomation} />
                    <Route path='/emailautomation' component={EmailAutomationHistory} />
                    <Route path='/customereditor' component={CustomerEditor} />
                    <Route path='/guide' component={Guide} />
                    <Route path='/svm' component={SubscriptionVsMaintenance} />
                    <Route path='/download' component={DownloadContainer} />
                    <Route path='/adminmenu' component={AdminMenu} />
                    <Route path='/adminpermission' component={AdminPermissionManager} />
                    <Route path='/cloudpermission' component={CloudPermissions} />
                    <Route path='/filemanager' component={FileManager} />
                    <Route path='/watchlist' component={WatchList} />
                    <Route path='/netsorders' component={NetsOrders} />
                </div>
            </>
        )
    }
}
